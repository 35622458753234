import React from 'react';
import {Row, Col} from 'antd';

const Careers = () => {
    return (
        <div className="main-careers">
            <Row>
                <Col span={12} style={CareersStyles.bg}>
                    &nbsp; fdsfd
                </Col>
                <Col span={12} className="careers-right">
                    <h1>CAREERS</h1>
                    <h2>Calling all extraordinary musicians!</h2>
                    <div className="careers-desc">
                        <p>Are you ready to embark on a remarkable journey with the most phenomenal film music orchestra ever created?<br />
                            This is your chance to be a part of an awe-inspiring ensemble that brings the magic of music from existing films to life!</p>
                        <p>Imagine performing breathtaking compositions from beloved films, immersing yourself in the heart and soul of cinematic masterpieces. You&lsquo;ll have the privilege of collaborating with a group of extraordinary musicians, each bringing their unique talent to the stage.</p>
                        <p>Send us your CV showcasing your exceptional musical prowess to the email address <a href="mailto:contact@motionpictureorchestra.com">contact@motionpictureorchestra.com</a>, and prepare for a symphonic adventure unlike any other! </p>
                        <p>We carefully review all CVs and get in touch with the chosen candidates to discuss the next steps of the recruitment process. Keep an eye on your inbox and get ready to embark on this extraordinary musical journey!</p>
                        <p>By sharing your CV, you give us the green light to preserve your data securely, ensuring we can reach out to you for the thrilling purpose of recruitment.</p>
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default Careers;

const CareersStyles = {
    bg: {
        backgroundImage: "url('./images/bg-careers.jpg')",
        backgroundSize: "cover",
    }
};