import React from 'react';
import {Row, Col} from 'antd';
const Repertoire = () => {


    return (
        <div className="main-repertoire">
            <div style={RepertoireStyles.hero}>
                <div className="main-title">Repertoire</div>
            </div>
            <Row className="repertoire-desc">
                <Col span={18} className="repertoire-inner">
                    <p>Prepare to be captivated as our orchestra unleashes a repertoire that spans the cinematic spectrum, transporting you to extraordinary realms. </p>
                    <p>From the timeless allure of "Gone With the Wind" and the swashbuckling adventures of "Pirates of the Caribbean" to the magical enchantment of classics like "The Lion King" and "Beauty and the Beast," our symphonic tapestry weaves together the iconic themes that have shaped film history.</p>
                    <p>Brace yourself for the epic battles of "Gladiator" and the intergalactic saga of "Star Wars," while also experiencing the soul-stirring melodies of "Schindler's List" and the haunting brilliance of "Requiem for a Dream."</p>
                    <p>This is a journey through the boundless power of film music, an unforgettable experience that will leave your heart racing and your imagination soaring.</p>

                    <p>In its repertoire, the orchestra features the following selection of pieces:</p>
                    <p>  <ol>
                        <li> How To Train Your Dragon Theme</li>
                        <li> Home Alone Theme</li>
                        <li> Shrek Theme</li>
                        <li> Mombasa</li>
                        <li> Ghost Theme</li>
                        <li> The Fifth Element Theme</li>
                        <li> Geronimo: An American Legend Theme</li>
                        <li> Game of Thrones</li>
                        <li> Lion King Suite</li>
                        <li> Gladiator Suite</li>
                        <li>Avatar Suite</li>
                        <li>Pirates of the Caribbean Suite</li>
                        <li> Titanic Suite</li>
                        <li>Indiana Jones Theme</li>
                        <li> Forrest Gump Theme</li>
                        <li> Captain America Theme</li>
                        <li> Braveheart Theme</li>
                        <li> Chicken Run Suite</li>
                        <li> The Lone Ranger Theme</li>
                        <li> The Mission Theme </li>
                        <li> The Lord of the Rings Suite</li>
                        <li> The Mummy Returns Theme</li>
                        <li> Zorba The Greek Theme</li>
                        <li> John Williams - The Best Themes Suite</li>
                        <li> The Last Mohican Theme</li>
                        <li> Chariots Of Fire Theme</li>
                        <li> Requiem For A Dream Theme</li>
                        <li>North and South Theme</li>
                        <li> Gone With the Wind Theme</li>
                        <li>Dracula Theme</li>
                        <li> The Mask of Zorro Theme</li>
                        <li> Frozen Suite</li>
                        <li> James Bond Theme</li>
                        <li> A-Team Theme</li>
                        <li> Rawhide Theme</li>
                        <li> The Prince Of Egypt Suite</li>
                        <li> Matrix Suite</li>
                        <li> Harry Potter Suite</li>
                        <li>Schindler&apos;s List Theme</li>
                        <li> Rocky Theme</li>
                        <li>Princess Mononoke Suite</li>
                        <li> The Avengers Theme</li>
                        <li> Kung Fu Panda Suite</li>
                        <li> Star Wars Suite</li>
                        <li>Madagascar Theme</li>
                        <li> Winnie the Pooh Theme</li>
                        <li> Beauty and the Beast Suite</li>
                        <li> Close Encounters of the Third Kind Theme</li>
                        <li> Coco Theme</li>
                        <li> Top Gun Theme</li>
                        <li>Star Trek Theme</li>
                        <li> Superman Theme</li>
                        <li> Phantom of the Opera Theme</li>
                        <li> Honor to Us All - Mulan</li>
                        <li> Singing In The Rain</li>
                        <li>Inception Suite</li>
                        <li>Sweet Home Chicago - The Blues Brothers</li>
                        <li>Let It Go - Frozen</li>
                        <li> Cantina Band - Star Wars</li>
                        <li> I Have Nothing - Bodyguard</li>
                        <li>The Time of My Life - Dirty Dancing</li>
                        <li> I See the Light - Tangled</li>
                        <li> I Will Follow Him - Sister Act</li>
                        <li> A Whole New World - Aladdin</li>
                        <li> Memory - Cats</li>
                        <li> Colors of the Wind - Pocahontas</li>
                        <li> Skyfall - James Bond</li>
                        <li> I do it for You - Robin Hood</li>
                        <li> If I were a rich man - Fiddler on the roof</li>
                        <li> I Dreamed A Dream - Les Miserables</li>
                        <li> Now we are free - Gladiator</li>
                        <li> I See You - Avatar</li>
                        <li> America - West Side Story</li>
                        <li> Be Our Guest - Beauty and the Beast</li>
                        <li> For the First Time in Forever - Frozen</li>
                        <li> Reflection - Mulan</li>
                        <li> Sherlock Holmes Theme</li>
                        <li>Somewhere Over the Rainbow - The Wizard of Oz</li>
                    </ol>
                    </p>
                </Col>
            </Row>
        </div>
    );



};

export default Repertoire;


const RepertoireStyles = {
    hero: {
        backgroundImage: "url('./images/bg-repertoire.png')",
        backgroundSize: "cover",
        height: "400px",
    }
};