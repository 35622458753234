import React from 'react';
import {Layout, Menu, theme, Row, Col} from 'antd';
import {MailOutlined, PhoneOutlined} from '@ant-design/icons';

const Footer = () => {

    const year = new Date().getFullYear();

    return (
        <Row className="footer">
            <Col span={18}>
                <Row className="footer-row">
                    <Col span={9}>
                        <h3 className="footer-title"> Motion Picture Orchestra</h3>
                        {/*<div className="footer-desc">
                            86-90, Paul Street, London, EC2A 4NE<br />
                            Registered in England no. 14942489 
                        </div>*/}
                    </Col>
                    <Col span={8}>
                        <div className="footer-links">
                            <div className="footer-links-section">
                                <a href="/">Home</a>
                                <a href="/orchestra">Orchestra</a>
                                <a href="/repertoire">Repertoire</a>
                                <a href="/careers">Careers</a>
                            </div>
                            <div className="footer-links-section">
                                <a href="/contact">Contact Us</a>
                                <a href="/privacy-policy">Privacy Policy</a>
                                <a href="https://www.facebook.com/MotionPictureOrchestra" target="_blank"><img src="/images/facebook.svg" alt="MPO Facebook profile" className="footer-social-img" /></a>
                            </div>
                        </div>
                    </Col>
                    <Col span={7} className="footer-info">
                        <div className="footer-info-line">
                            <a href="mailto:contact@motionpictureorchestra.com">
                                <MailOutlined className="icon" />
                            </a>
                            <a href="mailto:contact@motionpictureorchestra.com">
                                contact@motionpictureorchestra.com
                            </a>
                        </div>
                        <div className="footer-info-line">
                            <a href="tel:+447445560578">
                                <PhoneOutlined className="icon" />
                            </a>
                            <a href="tel:+447445560578">+44 7445 560578</a>
                        </div>

                    </Col>
                </Row>
                <div className="copyright">Copyright © {year} Motion Picture Orchestra</div>
            </Col>
        </Row>
    )
}

export default Footer;