import React, {useRef, useState} from 'react';
import emailjs from '@emailjs/browser';
import {Spin, notification} from 'antd';
import type {NotificationPlacement} from 'antd/es/notification/interface';

const ContactUs = () => {
    const [api, contextHolder] = notification.useNotification();
    const [name, setName] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [message, setMessage] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const form = useRef<HTMLFormElement>(null);

    //@ts-ignore
    const sendEmail = (e) => {
        e.preventDefault();
        setLoading(true);
        setTimeout(() => {
            emailjs.sendForm('service_u40gvfa', 'template_ofvjb5q', form.current!, 'V2pJjlNJE6M4xW6gS')
                .then((result) => {
                    openNotification(true, 'bottom', '');
                    setName('');
                    setEmail('');
                    setMessage('');
                    setLoading(false);
                }, (error) => {
                    openNotification(false, 'bottom', error.text);
                    setLoading(false);
                });
        }, 3000);
    };
    
    const openNotification = (success: boolean, placement: NotificationPlacement, message: string): void => {
        if (success) {
            api.success({
                message: 'Success',
                description:
                    'The email has been sent successfully.',
                placement,
            });
        } else {
            api.error({
                message: 'Error',
                description:
                    message,
                placement,
            });
        }
    };

    return (
        <>
            {contextHolder}
            <div className="main-contact-us">
                <div className="contact-form-box">
                    {loading && <div className="loader"> <Spin /></div>}

                    <div className="contact-form-title">Contact Us</div>
                    <form ref={form} onSubmit={sendEmail} className="form-container">
                        <label>Name</label>
                        <input
                            type="text"
                            name="user_name"
                            value={name}
                            onChange={(e) => setName(e.currentTarget.value)}
                            required
                        />
                        <label>Email</label>
                        <input
                            type="email"
                            name="user_email"
                            value={email}
                            onChange={(e) => setEmail(e.currentTarget.value)}
                            required
                        />
                        <label>Message</label>
                        <textarea
                            name="message"
                            value={message}
                            onChange={(e) => setMessage(e.currentTarget.value)}
                            required
                        />
                        <input type="submit" value="Send" />
                    </form>
                </div>
            </div>
        </>
    );
};

export default ContactUs;
