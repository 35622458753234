import React from 'react';

const PrivacyPolicy = () => {


    return (
        <div className="main-privacy-policy">
            <div className="privacy-policy-box">
                <center><h1>Privacy Policy</h1></center>
                <p>Effective Date: <em>24.06.2023</em></p>

                <p>This Privacy Policy governs the manner in which <em>Motion Picture Orchestra</em> collects, uses, maintains, and discloses information collected from users (referred to as "Users") of the <em>motionpictureorchestra.com</em> website (referred to as the "Website").</p>

                <h2>1. Information We Collect</h2>

                <p>We may collect personal identification information from Users in a variety of ways, including, but not limited to, when Users visit our Website, register on the Website, fill out a form, and in connection with other activities, services, features, or resources we make available on our Website. Users may be asked for, as appropriate, name, email address, mailing address, and phone number. Users may, however, visit our Website anonymously. We will collect personal identification information from Users only if they voluntarily submit such information to us. Users can always refuse to supply personal identification information, except that it may prevent them from engaging in certain Website-related activities.</p>

                <h2>2. How We Use Collected Information</h2>

                <p>We may collect and use Users&lsquo; personal information for the following purposes:</p>

                <ul>
                    <li>To improve customer service: Information provided by Users helps us respond to customer service requests and support needs more efficiently.</li>
                    <li>To personalize user experience: We may use information in the aggregate to understand how our Users as a group use the services and resources provided on our Website.</li>
                    <li>To improve our Website: We continually strive to improve our Website offerings based on the information and feedback we receive from Users.</li>
                    <li>To send periodic emails: We may use the email address to respond to inquiries, questions, and/or other requests from Users. If a User decides to opt-in to our mailing list, they will receive emails that may include company news, updates, related product or service information, etc. If at any time the User would like to unsubscribe from receiving future emails, we include detailed unsubscribe instructions at the bottom of each email.</li>
                </ul>

                <h2>3. How We Protect Your Information</h2>

                <p>We adopt appropriate data collection, storage, and processing practices, and security measures to protect against unauthorized access, alteration, disclosure, or destruction of your personal information, username, password, transaction information, and data stored on our Website.</p>

                <h2>4. Sharing Your Personal Information</h2>

                <p>We do not sell, trade, or rent Users&lsquo; personal identification information to others. We may share generic aggregated demographic information not linked to any personal identification information regarding visitors and users with our business partners, trusted affiliates, and advertisers for the purposes outlined above.</p>

                <h2>5. Changes to This Privacy Policy</h2>

                <p><em>motionpictureorchestra.com</em> has the discretion to update this Privacy Policy at any time. We encourage Users to frequently check this page for any changes to stay informed about how we are helping to protect the personal information we collect. You acknowledge and agree that it is your responsibility to review this Privacy Policy periodically and become aware of modifications.</p>

                <h2>6. Your Acceptance of These Terms</h2>
                <p>By using this Website, you signify your acceptance of this Privacy Policy. If you do not agree to this Privacy Policy, please do not use our Website. Your continued use of the Website following the posting of changes to this Privacy Policy will be deemed your acceptance of those changes.</p>
                <h2>7. Contacting Us</h2>
                <p>If you have any questions about this Privacy Policy, the practices of this Website, or your dealings with this Website, please contact us at <a href="mailto:contact@motionpictureorchestra.com">contact@motionpictureorchestra.com</a>.</p>
                <p>This Privacy Policy was last updated on <em>24.06.2023</em>.</p>
            </div>
        </div>
    );



};

export default PrivacyPolicy;
