import React, {useContext, ReactNode, useEffect, useState} from 'react';
import {Route, Routes, Navigate, Link} from 'react-router-dom';
import Home from './pages/Home';
import Orchestra from './pages/Orchestra';
import Repertoire from './pages/Repertoire';
import Careers from './pages/Careers';
import ContactUs from './pages/ContactUs';
import PrivacyPolicy from './pages/PrivacyPolicy';

const RoutesConfig: React.FC = () => {
    return (
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/orchestra" element={<Orchestra />} />
            <Route path="/repertoire" element={<Repertoire />} />
            <Route path="/careers" element={<Careers />} />
            <Route path="/contact" element={<ContactUs />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        </Routes>
    );
};

export default RoutesConfig;
