import React from 'react';
import {Row, Col} from 'antd';

const Orchestra = () => {


    return (
        <div className="main-orchestra">
            <div style={OrchestraStyles.hero}>
                <div className="main-title">Who We Are</div>
            </div>
            <Row className="about-orchestra-section">
                <Col span={12} className="about-orchestra-left">
                    <div className="about-desc">
                        <h2>Motion Picture Orchestra</h2>
                        <p> The Motion Picture Orchestra is an esteemed ensemble of symphony musicians, united by a profound love for the enchanting realm of orchestral music. We specialize in captivating audiences through our mesmerizing film music concerts, offering an immersive experience that leaves a lasting impact.</p>

                        <p>With a wealth of professional expertise garnered over the years in the music industry, we are committed to sharing the transformative power of music with the world. Our mission is to ignite a lifelong passion for film music, touching the hearts and souls of our listeners with every performance.</p>

                        <p>Driven by an unwavering enthusiasm, our elite team of musicians creates a symphonic tapestry that transports audiences into a world of captivating melodies. We meticulously craft each concert to evoke a range of emotions, from exhilarating crescendos to tender moments of introspection.</p>

                        <p>The Motion Picture Orchestra takes pride in curating unforgettable experiences that transcend the boundaries of sound and time. Through our performances, we invite listeners to embark on an extraordinary journey, where the harmonies of film music merge with personal narratives, creating an intimate connection that resonates deeply.</p>

                        <p>Join us as we embark on this musical odyssey, where the transformative power of film music comes alive. Experience the magic, the passion, and the extraordinary artistry of The Motion Picture Orchestra, and cultivate a lifelong appreciation for the captivating melodies that enrich our lives.</p>
                    </div>
                </Col>
                <Col span={12} className="about-orchestra-right">
                    <img src="/images/logo-title.png" alt="mpo-logo" className="about-logo" />
                </Col>
            </Row>
            <Row className="about-cast-section">
                <Col span={10} style={OrchestraStyles.castBanner} className="about-cast-left">
                    &nbsp;
                </Col>
                <Col span={14} className="about-cast-right">
                    <h2>Orchestra Cast</h2>
                    <Row className="orchestra-cast">
                        <Col span={8} className="orchestra-cast-col-left">
                            2 flauti - mutano in fl. picc.<br />
                            2 oboi <br />
                            2 clarinetti in si <br />
                            2 fagotti - mutano in cf. <br />
                            6 corni in fa <br />
                            4 trombe in si <br />
                            3 tromboni <br />
                            tuba

                        </Col>
                        <Col span={8} className="orchestra-cast-col">
                            timpani <br />
                            xilofono <br />
                            campanelli<br />
                            campane tubolari<br />
                            2 conghi<br />
                            gran cassa<br />
                            tamburo millitare<br />
                            piatti a due<br />
                            piatto sospeso<br />
                            tam-tam
                        </Col>
                        <Col span={8} className="orchestra-cast-col">
                            arpa<br />
                            pianoforte<br />
                            accordion<br />
                            chitarra<br />
                            bass chitarra<br />
                            <br />
                            violini I<br />
                            violini II<br />
                            viole<br />
                            violoncelli<br />
                            contrabassi
                        </Col>
                    </Row >
                </Col>
            </Row>
            <Row className="about-vision-section">
                <Col span={12} className="about-vision-left">
                    <div className="about-vision-desc">
                        <h2>Mission, Vision, & Values</h2>
                        <b>Vision:</b>
                        <p>  To be a global leader in creating immersive film music experiences that transcend the boundaries of sound and time, leaving a profound impact on audiences worldwide.</p>
                        <b>  Mission:</b>
                        <p>Our mission is to ignite and nurture a lifelong passion for film music by delivering mesmerizing and captivating performances. We strive to touch the hearts and souls of our listeners, creating transformative musical journeys that resonate deeply and evoke a range of emotions.</p>

                        <b>Values:</b>
                        <ol>
                            <li> Passion: We are driven by an unwavering love for orchestral music and a deep appreciation for the power of film music to transport and inspire.  </li>

                            <li> Excellence: We relentlessly pursue excellence in every aspect of our craft, from musical precision to performance artistry, ensuring a world-class experience for our audience.</li>

                            <li>  Creativity: We embrace creativity as the foundation of our musical expression, infusing our performances with innovative interpretations and unique arrangements that breathe new life into beloved film scores.</li>

                            <li> Connection: We value the connection between music and emotions, seeking to forge a profound and intimate bond with our audience through our performances, inviting them to embark on an emotional journey together.</li>

                            <li>  Authenticity: We stay true to the essence of each composition, honoring the original intent while infusing it with our unique artistry, bringing forth an authentic and powerful rendition.</li>

                            <li> Impact: We strive to create lasting impact through our performances, leaving our audience with a sense of wonder, inspiration, and a lifelong appreciation for the enchanting melodies of film music.</li>
                        </ol>
                        <p>  With this vision, mission, and set of values, The Motion Picture Orchestra aims to create transformative and unforgettable experiences that celebrate the magic of film music and enrich the lives of all who encounter our performances.</p>
                    </div>
                </Col>
                <Col span={12} style={OrchestraStyles.visionBanner} className="about-vision-right">
                    &nbsp;
                </Col>
            </Row>
            <Row className="about-ds-section">
                <Col span={12} className="about-ds-left" style={OrchestraStyles.dsBanner}>
                    &nbsp;
                </Col>
                <Col span={12} className="about-ds-right">
                    <div className="about-ds-desc">
                        <h2>Dariusz Swoszowski</h2>
                        <p> composer, conductor, and music executive,<br />
                            has made remarkable strides in the world of music through his multifaceted expertise.As the founder and conductor of the Crushed Sounds Big Band, he has curated 41 captivating concerts encompassing film, pop, and sacred music genres. His collaborations with renowned institutions such as Krzysztof Penderecki European Centre of Music and the European Orchestra Federation have showcased his commitment to artistic excellence and innovation. Notably, Swoszowski&lsquo;s talent as a symphonic music arranger for esteemed Polish Film Music Orchestra has further solidified his reputation as a distinguished musical artist.<br/>
                            With a diverse range of experiences, from teaching music theory and conducting orchestras to organizing contemporary and film music concerts, he has continually enriched the cultural landscape.
                        </p>
                    </div>
                </Col>
            </Row>
            {/*   Dariusz Swoszowski, founder and conductor of the Motion Picture Orchestra
            He graduated B.A., I.J.Paderewski Academy of Music in Poznań, composition class of Professor Janusz Stalmierski and M.A., St.Moniuszko Academy of Music in Gdańsk, composition class of Professor Andrzej Dziadek, film and theater composition class of Professor Krzysztof Olczak
            Musical and professional experience:
            since 2023 founder and conductor of the Motion Picture Orchestra
            2015 - 2023 founder and conductor of the Crushed Sounds Big Band which performed 41 concerts of film, pop and sacral music
            2011 - 2019 arranger and composer of over a hundred symphonic musical works for the Polish Film Music Orchestra
            2007 - 2012 co - organizer of contemporary and film music concerts in Poznań, Gdańsk and Tarnów
            2003 - 2006 founder and conductor of the Film Music Ensemble in Tarnów
            cooperated with a variety of artistic environments, e.g.: Theatre, City Culture Centre, Pueri Cantores Tarnovienses Choir, Gos.pl Choir in Tarnów, Poland
            symphonic music arranger for the stars of the Polish music scene: Beata Kozidrak(BAJM) and Kayah
            In the years 2013 - 2017 as a teacher of theoretical subjects and orchestra conductor at the Music School in Porąbka Uszewska, actively participated in the organization of over 40 concerts; author of educational publications for children and youth in the field of music training.
            Dariusz Swoszowski & lsquo; s music has been performed at concerts and festivals of classical music as part of the Talent Week in Tarnów, the Gdańsk New Music Festival "Gdańsk New Sounds" in the Polish Baltic F.Chopin Philharmonic, as well as in the Krzysztof Penderecki European Centre of Music, Lusławice.Dariusz Swoszowski is the creator of techniques which he describes as structural aleatorism, characterized by combined elements of aleatorism, sonorism and structuralism. */}
        </div >
    );
};

export default Orchestra;


const OrchestraStyles = {
    hero: {
        backgroundImage: "url('./images/bg-who-we-are.jpg')",
        backgroundSize: "cover",
        backgroundPosition: "center",
        height: "400px",
    },
    castBanner: {
        backgroundImage: "url('./images/about-cast.png')",
        backgroundSize: "cover",
        backgroundPosition: "center right",
    },
    visionBanner: {
        backgroundImage: "url('./images/vision.png')",
        backgroundSize: "cover",
        backgroundPosition: "center right",
    },
    dsBanner: {
        backgroundImage: "url('./images/d.swoszowski.jpg')",
        backgroundSize: "cover",
        backgroundPosition: "center right",
    },
};