import React, {useContext} from 'react';
//import {useWallet} from '@solana/wallet-adapter-react';
import Header from './Header';
import Footer from './Footer';


type LayoutProps = {
    children: React.ReactNode
}

const Layout = ({children}: LayoutProps) => {
    return (
        <>
            <Header />
            <main>{children}</main>
            <Footer />
        </>
    )
}

export default Layout;