import React from 'react';
import logo from './logo.svg';
import './App.css';
import RoutesConfig from './Routes';
import Layout from './components/Layout';
import {BrowserRouter as Router} from 'react-router-dom';

function App() {
    return (
        <Router>
            <Layout>
                <RoutesConfig />
            </Layout>
        </Router> 
    );
}

export default App;
