
import React, {useState, useEffect, useRef, useContext} from 'react';
import {Layout, Row, Col, Button, Drawer, Menu, Anchor, Modal, Dropdown} from 'antd';
import {LoginOutlined, MenuOutlined, WalletOutlined, UserAddOutlined} from '@ant-design/icons';
//import {useWallet} from '@solana/wallet-adapter-react';
//import {WalletMultiButton, WalletDisconnectButton} from "@solana/wallet-adapter-react-ui";


const Header = () => {

    return (
        <Row className="header">
            <Col span={4}>
                <a href="/"><img src="/images/logo-mpo.png" alt="Motion Picture Orchestra Logo" className="header-logo" /></a>
            </Col>
            <Col span={14} className="header-links">
                <a href="/">Home</a>
                <a href="/orchestra">Who We Are</a>
                <a href="/repertoire">Repertoire</a>
                <a href="/careers">Careers</a>
                <a href="/contact">Contact Us</a>
            </Col>
        </Row>
    )
}
export default Header;
