import React, {useState, useEffect, useContext} from 'react';
import {Row, Col, Form, Input, Button, Progress, Space, Card} from 'antd';
import {LockOutlined} from '@ant-design/icons';
const {Meta} = Card;
const Home = () => {

    const validateMessages = {
        required: 'Email is required.',
        types: {
            email: 'Email is not a valid.',
        }
    };

    const addToWhitelisting = (values: any): void => {
        console.log(values);
    };

    const addToNewsletter = (values: any): void => {
        console.log(values);
    };

    return (
        <div className="main">
            <div style={HomeStyles.hero}>
                <div className="main-title title-homepage">Motion Picture Orchestra</div>
            </div>
            <Row className="home-sections">
                <Col span={5} className="home-sections-col">
                    <a href="/orchestra">
                        <Card
                            hoverable
                            cover={<img alt="orchestra" src="/images/h-orchestra.jpg" />}
                        >
                            <Meta title="About the MPO" description="Experience the Magic of The Motion Picture Orchestra" />
                        </Card>
                    </a>
                </Col>
                <Col span={5} className="home-sections-col">
                    <a href="/repertoire">
                        <Card
                            hoverable
                            cover={<img alt="repertoire" src="/images/h-repertoire.jpg" />}
                        >
                            <Meta title="Music we play" description="Explore the Captivating Cinematic Repertoire" />
                        </Card>
                    </a>
                </Col>
                <Col span={5} className="home-sections-col">
                    <a href="/careers">
                        <Card
                            hoverable
                            cover={<img alt="careers" src="/images/h-careers.jpg" />}
                        >
                            <Meta title="Join our orchestra" description="Take Your Musical Journey to New Heights" />
                        </Card>
                    </a>
                </Col>
            </Row>
            <Row className="magic-section">
                <Col span={12} style={HomeStyles.orchestraBanner} className="magic-section-left">
                    &nbsp;
                </Col>
                <Col span={12} className="about-ds-right">
                    <div className="about-ds-desc">
                        <h2>The Magic of Film Music</h2>
                        <p> At The Motion Picture Orchestra, our mission is simple yet profound: to deliver mesmerizing performances that ignite a lifelong passion for film music. We strive to transcend boundaries, leaving a lasting impact on audiences worldwide. Driven by unwavering passion and a deep appreciation for the power of music, we infuse our performances with creativity, authenticity, and excellence.  </p>

                        <p>With innovative interpretations and unique arrangements, we breathe new life into beloved film scores, forging a profound connection between music and emotions. Our aim is to create transformative experiences that resonate deeply, taking our audience on an emotional journey they&apos;ll never forget. We stay true to the essence of each composition, infusing it with our artistry to create an authentic and powerful rendition.  </p>

                        <p>Our performances are designed to leave a lasting impact, inspiring a sense of wonder and appreciation for the enchanting melodies of film music. With each note, we invite our audience to embark on a magical adventure, where the boundaries of sound and time are transcended.  </p>

                        <p>Welcome to The Motion Picture Orchestra, where harmonies come alive, and the magic of film music takes center stage. Prepare to be enchanted as we elevate your soul with our captivating performances.
                        </p>
                    </div>
                </Col>
            </Row>
            <Row className="home-sections">
                <Col span={8} className="home-technicals-col-img">
                    <img src="/images/h-musicians.jpg" alt="musicians" />
                </Col>
                <Col span={8} className="home-technicals-col-text">
                    <div className="technicals-title">Musicians</div>
                    <div className="technicals-desc">The orchestra consists exclusively of experienced professional musicians,
                        film music enthusiasts, who guarantees performance of musical themes at a very high level.</div>
                </Col>
            </Row>
            <Row className="home-sections">
                <Col span={8} className="home-technicals-col-text">
                    <div className="technicals-title">Music Arrangemenets</div>
                    <div className="technicals-desc">Each piece is directed on the basis of precisely prepared musical arangements,
                        written speciall for our orchestra, which provides an uforgettalbe listening experience.</div>
                </Col>
                <Col span={8} className="home-technicals-col-img">
                  <img src="/images/h-arr.jpg" alt="music arrangemenets" />
                </Col>
            </Row>
            <Row className="home-sections">
                <Col span={8} className="home-technicals-col-img">
                  <img src="/images/h-visual.jpg" alt="visual effects" />
                </Col>
                <Col span={8} className="home-technicals-col-text">
                    <div className="technicals-title">Visual Effects</div>
                    <div className="technicals-desc">Modern and directed visual effects add drama and excitement to each of live performances,
                        providing unique emotions and artistics sensations.</div>
                </Col>
            </Row>
            <Row>
                <Col span={24} className="stay-in-touch">
                    <div className="newsletter-title">STAY IN TOUCH</div>
                    <form>
                        <input
                            type="email"
                            placeholder="Email address"
                            className="newsletter-input" />
                        <input type="submit" value="Signup To Newsletter" />
                    </form>
                </Col>
            </Row>
        </div >
    );



};

export default Home;


const HomeStyles = {
    hero: {
        backgroundImage: "url('./images/bg-mpo.jpg')",
        backgroundSize: "cover",
        backgroundPosition: "center",
        height: "500px",
    },
    orchestraBanner: {
        backgroundImage: "url('./images/bg-home-orchestra.jpg')",
        backgroundSize: "cover",
        backgroundPosition: "center right",
    }
};